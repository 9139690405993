@media print {
  // Target: Zendesk widget
  iframe[title="Button to launch messaging window"] {
    display: none;
  }

  mat-tooltip-component {
    display: none;
  }
}
