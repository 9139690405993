@use 'fl-buttons';
@use 'fl-colors' as colors;
@use 'fl-responsive' as responsive;
@use 'fl-typography' as typography;

// Overrides for mat-dialog-modals
// TODO: [Modal Refactor]
// Import this file to style native modals to custom modal used in Settings MFA
// TODO: This is an overzealous implementation and we shouldn't be applying it
// globally, which we are through fl-themes. Instead we should move as many of
// these styles and sub classes into fl-modal-container so we can override things
// properly
%mat-modals {
  .mat-mdc-dialog-container {
    @include responsive.max-resolution(mobile) {
      border-radius: 0;
      bottom: 0;
      box-shadow: none;
      left: 0;
      position: fixed;
      max-height: none;
      max-width: none;
      right: 0;
      top: 0;
    }

    border-radius: .25rem;
    box-shadow: 0 .5rem 1.25rem .125rem colors.$black-transparent-10;
    padding: 0;
    position: relative;

    h1 {
      @include typography.font-heading-1;
      margin: 0 0 1.5rem;

      &.modal-title {
        font-size: 1.375rem;
        line-height: 1.136365;
        margin-bottom: 1rem;
      }
    }

    h2 {
      &.modal-sub-heading {
        @include typography.font-heading-4;
      }
    }

    p:not(.empty-members) {
      color: colors.$fig-shade-40;
    }

    p { @include typography.font-default; }

    .modal-info {
      background: colors.$blueberry-tint-90;
    }

    // hr {
    hr:not(.grid-hr),
    .modal-hr {
      background: colors.$fig-shade-40;
      border: 0;
      border-radius: .0625rem;
      height: .125rem;
      margin: 2rem 0 2rem;
      opacity: .1;
    }

    .constrained-hr {
      background: colors.$fig-tint-80;
      border: none;
      height: .125rem;
      margin: 1.625rem 0;
    }

    .mat-mdc-raised-button {
      @extend %button-filled;
    }

    .mat-mdc-button {
      @extend %button-outline;
    }

    .mat-mdc-raised-button,
    .mat-mdc-button {
      @extend %button-mat-overrides;
    }
  }
}

%fl-modal-container {
  @include responsive.max-resolution(mobile) {
    max-height: none;
    max-width: none;
    padding: 1.5rem;
    width: auto;
  }

  display: block;
  max-height: calc(90vh - 6rem);
  max-width: 100%;
  overflow: auto;
  padding: 2.5rem;
  width: 31rem;
  transition: width ease-in-out 300ms;

  &.larger {
    width: 41rem;
  }

  fl-message-box {
    margin: 1rem 0;
  }

  fl-collection-table {
    .table-container.sticky {
      th {
        background: colors.$white;
      }
    }
  }

  .modal-buttons {
    @include responsive.max-resolution(mobile) {
      grid-auto-flow: dense;
    }

    display: grid;
    gap: 1rem;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    margin-top: 2rem;

    button {
      &:first-of-type {
        @extend %button-outline;
      }

      &:last-of-type:not(.destructive) {
        @extend %button-filled;
      }

      &.destructive {
        @extend %button-filled-red;
      }
    }
  }

  .modal-buttons-without-top-margin {
    @extend .modal-buttons;

    margin-top: 0;
  }

  .modal-button-cancel {
    @extend %button-outline;
    width: 100%;
  }

  .modal-close {
    background: none;
    border: 0;
    cursor: pointer;
    margin: 1rem;
    outline: none;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;

    svg {
      width: 1.5rem;

      path {
        fill: colors.$fig-tint-80;
      }
    }

    &:hover {
      svg {
        path {
          fill: colors.$grey;
        }
      }
    }
  }

  .modal-heading {
    font-size: 1rem;
    line-height: 1.25;
  }
}

%modal-form-field {
  @include typography.font-label-caps;

  .mat-mdc-form-field {
    display: block;
    margin-top: .6875rem;
  }

  .mat-mdc-input-element {
    font-size: .875rem;
    padding: .25rem 0;
  }
}

%modal-slide-field {
  @include typography.font-default;
  font-weight: bold;

  & + p {
    margin-top: .25rem;
    margin-left: 2.8125rem;
  }
}
