// "Use" is the new "import" for Sass 3.3+, and helps to keep imports isolated
// to only the context necessary, use these when getting shared values apply
// within the current scope / file
@use 'fl-colors' as colors;
@use 'fl-typography' as typography;

// The only styles that should go in here are those that pertain
// to body, and html, almost anything else can (and should) be
// abstracted to one of the layout styles. If something needs
// to be shared between those it should go into an import
// that can then be shared as necessary
@import url('https://use.typekit.net/agl8wsc.css');
@import 'normalize';
@import 'fl-print-globals';
@import 'fl-theme';

// This is used for debug purposes only, gives us a global class called
// .debug-info to use in template debugging
@import 'fl-debug-info';

body,
html {
  -webkit-font-smoothing: antialiased;
  background-color: colors.$white;
  box-sizing: border-box;
  color: colors.$fig-shade-40;
  font-family: typography.$body-font;
  font-size: 1rem;
  font-weight: 400;
  height: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
