// Breakpoints Definitions
// 1280+ is our current "desktop-large" threshold, it's also what we optimize for
// as far as designs and use case, so we should consider it the default
// and apply max scopes for each tier after
// 1024 is the "standard desktop" breakpoint, so anything less than that
// we could assume is a tablet
// 768px (points) is the standard tablet break point, so anything less than that
// should be treated as mobile.

$tablet-break: 1279px;
$mobile-break: 767px;

$desktop: $tablet-break + 1px;
$tablet: $mobile-break + 1px;

@mixin high-density-display {
  @media (min-resolution: 192dpi) {
    @content;
  }
}

@mixin max-resolution($size) {
  @if $size == tablet {
    @media (max-width: $tablet-break) {
      @content;
    }
  } @else if $size == mobile {
    @media (max-width: $mobile-break) {
      @content;
    }
  } @else {
    @media (max-width: $size) {
      @content;
    }
  }
}

@mixin min-resolution($size) {
  @if $size == tablet {
    @media (min-width: $tablet) {
      @content;
    }
  } @else {
    @media (min-width: $size) {
      @content;
    }
  }
}
