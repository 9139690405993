$border-radius: .25rem;
$chevron-down-dark-grey: 'data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20style%3D%22fill%3A%20var%28--chevron-down-color%2C%20%23666%29%22%20d%3D%22M15.5%2010.3c.4.4.4%201%200%201.4l-2.8%202.8c-.4.4-1%20.4-1.4%200l-2.8-2.8c-.4-.4-.4-1%200-1.4s1-.4%201.4%200l2.1%202.1%202.1-2.1c.4-.4%201-.4%201.4%200z%22/%3E%3C/svg%3E';
$chevron-down-grey: 'data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2024%2024%22%3E%3Cpath%20fill=%22%23a3a3a8%22%20d=%22M15.5%2010.3c.4.4.4%201%200%201.4l-2.8%202.8c-.4.4-1%20.4-1.4%200l-2.8-2.8c-.4-.4-.4-1%200-1.4s1-.4 1.4%200l2.1%202.1%202.1-2.1c.4-.4%201-.4%201.4%200z%22/%3E%3C/svg%3E';
$chevron-down-red: 'data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2024%2024%22%3E%3Cpath%20fill=%22%23cc0000%22%20d=%22M15.5%2010.3c.4.4.4%201%200%201.4l-2.8%202.8c-.4.4-1%20.4-1.4%200l-2.8-2.8c-.4-.4-.4-1%200-1.4s1-.4 1.4%200l2.1%202.1%202.1-2.1c.4-.4%201-.4%201.4%200z%22/%3E%3C/svg%3E';
$chevron-right-grey: 'data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"%3E%3Cpath fill="%23a3a3a8" d="M11.7,16.9c-0.4,0.4-1,0.4-1.4,0s-0.4-1,0-1.4l3.5-3.5l-3.5-3.5c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l4.2,4.2l0,0 c0.4,0.4,0.4,0.9,0.1,1.3l-0.1,0.1l0,0L11.7,16.9z"/%3E%3C/svg%3E';
$layout-margin-width: 2.25rem;
$layout-margin-width-mobile: 1.5rem;
$layout-margin-end: 5rem;

%invisible-block {
  display: block;
  height: 0;
  width: 0;
  line-height: 0;
  border: 0;
  opacity: 0;
}
// Indexes
$index-layer-0: 0;
// used by side-panel
$index-layer-1: 101;
// used by app layout header
$index-layer-2: 201;
// used by angular cdk and froala popup
$index-layer-100: 1000;
