@use 'fl-colors' as colors;

$body-font: roboto, sans-serif;
$header-font: trade-gothic-next, sans-serif;
$header-font-condensed: trade-gothic-next-condensed, sans-serif;
$signature-font: 'Shadows Into Light', cursive;

@mixin regular-text {
  font-size: .875rem;
  line-height: 1.5rem;
}

@mixin small-text {
  font-size: .6875rem;
  line-height: 1rem;
}

@mixin small-no-decoration-link {
  font-size: .875rem;
  text-decoration: none;
  padding-right: 1.5rem;
}

// Used in section
@mixin font-form-default {
  color: colors.$fig-shade-80;
  font-family: $body-font;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

@mixin font-heading-1 {
  color: colors.$fig-shade-80;
  font-family: $header-font;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: -.0625rem;
  line-height: 1.2;
}

@mixin font-heading-1-condensed {
  @include font-heading-1;

  font-family: $header-font-condensed;
}

@mixin font-heading-2 {
  color: colors.$fig-shade-80;
  font-family: $header-font;
  font-size: 1.875rem;
  font-weight: 700;
  letter-spacing: -.03125rem;
  line-height: 1.125;
}

@mixin font-heading-2-condensed {
  @include font-heading-2;

  font-family: $header-font-condensed;
}

@mixin font-heading-3 {
  color: colors.$fig-shade-80;
  font-family: $header-font;
  font-size: 1.375rem;
  font-weight: 700;
  letter-spacing: -.0125rem;
  line-height: 1.16666; // TODO: adjust to bring closer to full pixel match
}

@mixin font-heading-3-subview {
  @include font-heading-3;
  font-size: 1rem;
}

@mixin font-heading-3-condensed {
  @include font-heading-3;

  font-family: $header-font-condensed;
}

@mixin font-heading-4 {
  color: colors.$fig-shade-80;
  font-family: $header-font;
  font-size: 1.125rem;
  letter-spacing: 0;
  line-height: 1.16666;
}

@mixin font-heading-4-condensed {
  @include font-heading-4;

  font-family: $header-font-condensed;
}

@mixin font-heading-5 {
  color: colors.$fig-shade-80;
  font-family: $header-font;
  font-size: 1rem;
  line-height: 1.25;
}

@mixin font-heading-5-condensed {
  @include font-heading-5;

  font-family: $header-font-condensed;
}

@mixin font-signature-default {
  font-family: $signature-font;
  font-size: 3.125rem;
  line-height: 1.125;
  text-align: center;
}

@mixin font-default {
  font-family: $body-font;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.42857;
}

@mixin font-paragraph-2 {
  font-family: $body-font;
  font-size: 1.125rem;
  font-weight: 400;
}

@mixin button-font {
  font-family: $body-font;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5625;
}

@mixin font-small {
  font-family: $body-font;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1.5;
}

@mixin font-meta {
  font-family: $body-font;
  font-size: .6875rem;
  font-weight: 400;
  line-height: 1.4545; // TODO: Revisit find a value that gets interpreted as whole pixel
}

@mixin font-label {
  font-family: $body-font;
  font-size: .6875rem;
  font-weight: 700;
}

@mixin font-label-caps {
  @include font-label;
  color: colors.$fig-shade-40;
  letter-spacing: .0625rem;
  text-transform: uppercase;
}

@mixin font-hint {
  @include font-small;
  color: colors.$grey;
  letter-spacing: normal;
  text-transform: none;
}

@mixin font-tags {
  font-family: $body-font;
  font-size: .5625rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: .75;
  text-align: center;
  text-transform: uppercase;
}

@mixin font-select {
  font-size: .875rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5;
}

@mixin font-link {
  @include font-default;
  color: var(--link-color, colors.$blueberry);
  cursor: pointer;
  font-weight: 400;
  text-decoration: none;

  &:hover,
  &:focus {
    color: var(--link-color-hover, colors.$blueberry-shade-40);
  }

  &:disabled,
  &.disabled {
    color: var(--link-color-disabled, colors.$grey);
    cursor: default;
    opacity: .3;
  }
}

@mixin workflow-heading-1 {
  color: colors.$fig-shade-40;
  font-family: $body-font;
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.5555;
  margin: 0 0 1rem;
}

@mixin footer-text {
  color: colors.$white;
  font-family: $body-font;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.5;
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin form-field-error {
  color: colors.$grapefruit;
  font-size: .5625rem;
  line-height: 1rem;
  margin: .5rem 0 0;
}
